import React, { useState, useEffect } from 'react';
import { getCategory } from '../services/services';
import * as language from '../constants/languages';
import HeaderMain from '../components/HeaderMain';
import CategoryGrid from '../components/CategoryGrid';
import SpinnerLoader from '../components/SpinnerLoader';
import '../styles/CategoryPage.css'

export default function CategoryPage() {
	const [isLoading, setisLoading] = useState(true)
	const [categoryData, setcategoryData] = useState([])

	useEffect(() => {
		getCategoryData();
	}, [])

	async function getCategoryData() {
		const branchId = localStorage.getItem('storeId');
		await getCategory(language.companyidValue, branchId).then((data) => {
			if (data.status === "success") {

				setisLoading(false)
				setcategoryData(data.data)
				console.log(data)
			} else {

				setisLoading(false)

			}
		})
			.catch((error) => { })
	}
	return (
		<div >
			<HeaderMain />
			{
				isLoading == true ?
					<div className='spinnerContainer'>
						<SpinnerLoader />
					</div>
					:

					<div style={{ flex: 1, alignItems: 'center', marginTop: 30, paddingHorizontal: 10 }} >
						<CategoryGrid
							categoryData={categoryData}

						/>
					</div>

			}
		</div>
	)
}